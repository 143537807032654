<script>
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import ResourceForm from "@/components/TestCreation/Resources/ResourceForm.vue";
import Constants from "@/Constants";
import Tag from "@/components/Global/Tag.vue";
import ModalWindow from "@/components/Global/ModalWindow.vue";
import BtnSecondary from "@/components/Global/BtnSecondary.vue";
import { router } from "@inertiajs/vue3";

export default {
    components: {
        BtnSecondary,
        BtnPrimary,
        Tag,
        ResourceForm,
        ModalWindow,
    },

    data() {
        return {
            grades: Constants.grades,
            processing: false,
            resource: {},
            showDuplicateModal: false,
            states: Constants.states,
            topics: Constants.topics,
        };
    },

    props: {
        data: {
            default() {
                return {};
            },
        },

        create: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        editing() {
            return this.edit || this.create || route().current("test-creator.resources.edit");
        },
    },

    methods: {
        cancel() {
            if (this.create) {
                router.get(route("resources.index"));
            } else {
                router.get(route("resources.show", [this.resource]));
            }
        },

        duplicateResource(withTests = false) {
            this.processing = true;

            axios
                .post(route("resources.duplicate", [this.resource]), {
                    with_tests: withTests,
                })
                .then((response) => {
                    router.get(route("resources.show", [response.data.resource_id]));
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.processing = false;
                });
        },

        editResource() {
            router.get(route("test-creator.resources.edit", [this.resource]));
        },

        resourceCreated(resource) {
            this.resource = { ...resource };

            router.get(route("resources.show", [resource]));
        },

        resourceUpdated(resource) {
            this.resource = resource;

            router.get(route("resources.show", [resource]));
        },
    },

    created() {
        if (this.data.id !== undefined) {
            this.resource = { ...this.data };
        }
    },
};
</script>

<template>
    <div class="container mx-auto flex h-full flex-wrap content-start p-6">
        <div
            class="flex w-full flex-wrap content-start"
            v-if="resource.created_at !== undefined && !editing"
        >
            <div class="w-full pr-4 sm:w-1/2 md:w-1/4 lg:w-1/5 xl:w-1/6">
                <div class="relative overflow-hidden pb-125">
                    <img
                        :src="resource.image_url"
                        :alt="resource.title"
                        class="absolute w-full object-cover"
                    />
                </div>

                <div class="mt-4 flex flex-col">
                    <BtnPrimary
                        v-if="resource.ebook_toc"
                        type="link"
                        :href="route('resources.eBook', resource.id)"
                        target="_blank"
                        aria-label="Open in a new tab"
                        class="mt-2 block"
                    >
                        View eBook
                    </BtnPrimary>

                    <BtnPrimary
                        v-if="resource.teacher_guide_toc"
                        type="link"
                        :href="route('resources.teacher-guide', resource.id)"
                        target="_blank"
                        aria-label="Open in a new tab"
                        class="mt-2 block"
                        >View Teacher's Guide
                    </BtnPrimary>

                    <BtnPrimary
                        type="link"
                        :href="route('test-creator.resources.activity-logs', resource.id)"
                        aria-label="Open in a new tab"
                        class="mt-2 block"
                        >Activity Logs
                    </BtnPrimary>
                </div>
            </div>

            <div class="h-full w-full sm:w-1/2 md:w-3/4 lg:w-4/5 xl:w-5/6">
                <div class="items-center border-b border-black py-2 md:flex">
                    <h3 class="flex-grow text-2xl">
                        {{ resource.title }}
                    </h3>

                    <div
                        class="flex-grow"
                        v-if="resource.media_needs_copied"
                    >
                        <span class="rounded-full bg-warning px-3 py-2"> Media duplication is in process... </span>
                    </div>

                    <div class="flex">
                        <BtnPrimary
                            :href="route('home')"
                            class="whitespace-nowrap"
                        >
                            <FontAwesomeIcon icon="chevron-left" />
                            Back
                        </BtnPrimary>

                        <BtnPrimary
                            @click="showDuplicateModal = true"
                            class="ml-2 whitespace-nowrap"
                            v-if="!editing"
                        >
                            <FontAwesomeIcon icon="copy" />
                            Clone
                        </BtnPrimary>

                        <BtnPrimary
                            @click="editResource"
                            class="ml-2 whitespace-nowrap"
                            v-if="!editing"
                        >
                            <FontAwesomeIcon icon="edit" />
                            Edit Resource
                        </BtnPrimary>
                    </div>
                </div>

                <div class="mt-3">
                    <Tag v-if="resource.published"> Open to Public </Tag>

                    <Tag v-if="resource.topic">
                        {{ topics[resource.topic] }}
                    </Tag>

                    <Tag
                        v-for="grade in resource.grades"
                        :key="grade"
                    >
                        {{ grades[grade] }}
                    </Tag>

                    <Tag v-if="resource.states && resource.states.length >= 50"> All States </Tag>

                    <Tag
                        v-else
                        v-for="state in resource.states"
                        :key="state"
                    >
                        {{ states[state] }}
                    </Tag>
                </div>

                <slot :resource="resource"></slot>
            </div>
        </div>

        <ResourceForm
            :resource="resource"
            class="flex w-full flex-wrap content-start pb-6"
            @created="resourceCreated"
            @updated="resourceUpdated"
            v-else
        >
            <template #cancel="slotProps">
                <BtnSecondary
                    class="text-base"
                    :disabled="slotProps.saving"
                    :class="{ 'mb-6': slotProps.titleErrors }"
                    @click="cancel"
                >
                    <FontAwesomeIcon icon="times" />
                    Cancel
                </BtnSecondary>
            </template>
        </ResourceForm>

        <ModalWindow
            v-model:show="showDuplicateModal"
            header-classes="modal-header px-5 pt-5 text-center"
            footer-classes="flex justify-center modal-footer border-t px-5 pb-5 text-center w-1/2 mx-auto"
        >
            <template #header>
                <h5 class="text-2xl uppercase">Cloning Resource?</h5>
            </template>

            <div class="pb-4 text-center">Do you want to clone the tests with the resource?</div>

            <template #footer>
                <BtnPrimary
                    @click="duplicateResource(true)"
                    class="w-auto"
                    :class="{ 'opacity-25': processing }"
                    :disabled="processing"
                >
                    Yes
                </BtnPrimary>

                <BtnSecondary
                    class="ml-2 w-auto text-base"
                    @click="duplicateResource(false)"
                    :class="{ 'opacity-25': processing }"
                    :disabled="processing"
                >
                    No
                </BtnSecondary>
            </template>
        </ModalWindow>
    </div>
</template>
